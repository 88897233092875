import UserModel from '@/models/users.model'
export default {
    namespaced: true,
    //state: 해당 파일에서 관리하는 상태값 -> state를 변경하기 위해서는 mutations 내부의 변수를 commit으로 실행
    state: () => ({
        isLogin: false,
        userInfo: {
            id: 0,
            nickname: '',
            photo:'',
            role: 'STUDENT',
            isAdmin: false,
            isTeacher: false,
            isManager: false,
            isIT: false
        }
    }),
    // mutations: state를 변경하기 위해 실행되는 것으로 비동기를 해야할 경우
    mutations: {
        setLogin (state) {
            state.isLogin = UserModel.isLogin()
        },
        setUserInfo (state, payload) {
            if(payload === null) {
                state.userInfo.id = 0
                state.userInfo.nickname = ''
                state.userInfo.photo = ''
                state.userInfo.role = 'STUDENT'
                state.userInfo.isAdmin = state.userInfo.role === 'ADMIN'
                state.userInfo.isManager = state.userInfo.role === 'ADMIN' || state.userInfo.role === 'MANAGER'
                state.userInfo.isTeacher = state.userInfo.role === 'ADMIN' || state.userInfo.role === 'MANAGER' || state.userInfo.role === 'TEACHER'
            }
            else {
                for(let column in payload) {
                    if(typeof state.userInfo[column] !== 'undefined') {
                        state.userInfo[column] = payload[column]
                    }
                }
                state.userInfo.isAdmin = state.userInfo.role === 'ADMIN'
                state.userInfo.isManager = state.userInfo.role === 'ADMIN' || state.userInfo.role === 'MANAGER'
                state.userInfo.isTeacher = state.userInfo.role === 'ADMIN' || state.userInfo.role === 'MANAGER' || state.userInfo.role === 'TEACHER'
            }
        }
    },
    getters: {
        isLogin (state) {
            return state.isLogin
        },
        isAdmin (state) {
            return state.userInfo.role === 'ADMIN'
        },
        userInfo (state) {
            return state.userInfo
        }
    }
}