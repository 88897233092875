export default {
    namespaced: true,
    state: () => ({
        isLoading: false,
        useLayout: false,
        mainTitle: '',
        theme: 'front'
    }),
    mutations: {
        setLoading (state, payload) {
            state.isLoading = payload
        },
        setLayout (state, payload) {
            state.useLayout = payload
        },
        setMainTitle (state, payload) {
            state.mainTitle = payload
        },
        setTheme (state, payload) {
            state.theme = payload
        }
    },
    actions: {

    },
    getters: {
        mainTitle (state) {
            return state.mainTitle
        },
        isPageLoading (state) {
            return state.isLoading
        },
        useLayout (state) {
            return state.useLayout
        },
        theme (state) {
            return state.theme
        }
    }
}