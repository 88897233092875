import Vue from 'vue'
import VueRouter from 'vue-router'
import usersModel from '@/models/users.model'
import store from '@/store'

Vue.use(VueRouter)

/** 분리되어있는 routes 파일들 **/
import UsersRoutes from './users.routes'
import AdminRoutes from './admin.routes'
import LectureRoutes from './lecture.routes'
import CodeReviewRoutes from './codereview.routes'
import quizRouters from "@/router/quiz.routers";

const routes = [
  { path: '/', name: 'MainDashboard', component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashBoard') },
  { path: '/ai-chat', name: 'ChatGPT', component: () => import(/* webpackChunkName:"chatgpt" */ '../views/Components/ChatGPT'), meta: {useLayout:false} },
  { path: '/xr-edu', name: 'xrEdu', component: () => import(/* webpackChunkName:"xredu" */ '../views/Xr.vue')},
  ...UsersRoutes,
  ...LectureRoutes,
  ...CodeReviewRoutes,
  ...AdminRoutes,
  ...quizRouters,
  { path: '*', name: 'Error404NotFound', component: () => import(/* webpackChunkName: "error.404" */ '../views/errors/Error404'), meta: {requiredLogin:false, useLayout:false} }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})


router.beforeEach((to,from, next) => {
  const isRequiredLogin = !(typeof to.meta !== 'undefined' && typeof to.meta.requiredLogin !== 'undefined' && to.meta.requiredLogin === false);
  const useLayout = !(typeof to.meta !== 'undefined' && typeof to.meta.useLayout !== 'undefined' && to.meta.useLayout === false);
  const theme = to.meta?.theme === 'admin' ? 'admin' : 'front'

  store.commit('layout/setLayout', useLayout);
  store.commit('layout/setMainTitle', '')
  store.commit('layout/setTheme', theme)

  if(isRequiredLogin && ! usersModel.isLogin()) {
    next('/sign-in')
  }
  else {
    next();
  }
})

export default router
