<template>
    <div>
        <div class="user-box container--fluid d-flex justify-space-between align-center" :class="{minify:mini}" >
          <span class="close-box" v-if="myLectureList.result.length === 0"></span>
          <h2 class="--main-title" v-if="mainTitle.length>0" v-html="mainTitle" />
          <v-spacer />
          <template v-if="isLogin">
            <div class="user-photo-wrap d-flex flex-nowrap align-center justify-end">
            <div class="img-box-container">
              <div class="img-box">
                <v-avatar width="60" height="60">
                  <v-img :src="loginUser.photo?loginUser.photo:'http://placehold.it/200x200'" style="margin:8px;" />
                </v-avatar>
                <v-btn class="btn-upload-photo" icon @click="$refs.uploadInput.click()"><v-icon>mdi-camera</v-icon></v-btn>
                <input type="file" hidden class="upload-input" ref="uploadInput" accept="image/*" @change="onFileChange">
              </div>
            </div>
            <strong class="ml-2">{{loginUser.nickname}}</strong>
            <div class="info-box-container d-flex">
              <div class="d-flex align-center">
                <!-- v-btn to="/classroom" color="black" dark small inline height="30" elevation="0"><v-icon small left>mdi-alpha-c-circle</v-icon>강의실입장</v-btn-->
                <template>
                  <div class="text-xs-center">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            color="gray"
                            outlined
                            small
                            inline
                            height="30"
                            elevation="0"
                            class="ml-2"
                            style="z-index: 1000"
                            v-on="on">
                          <v-icon small left>mdi-account</v-icon>
                          내정보
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item link>
                          <v-list-item-title smal @click="ui.infoChange=true">정보수정</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item link>
                          <v-list-item-title small @click="ui.passwordChange=true">비밀번호 변경</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <!-- v-list-item link>
                          <v-list-item-title small @click="ui.infoSocial=true">소셜 로그인 설정</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider-->
                        <v-list-item link>
                          <v-list-item-title small @click="signOut">로그아웃</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </template>
              </div>
            </div>
          </div>
          </template>
        </div>
        <v-navigation-drawer app clipped v-model="drawer"
                             :mini-variant.sync="miniVariant"
                             permanent class="nav overflow-y-auto d-flex flex-column" v-scroll.self="onScroll">
          <div class="d-flex justify-space-around pl-3 align-center logo-box">
            <v-img :width="30" :height="34" aspect-ratio="1/1" contain
                   src="https://www.ezenac.co.kr/m/images/common/logo.png"
                   class="d-inline-block"></v-img>
            <v-list-item-title class="logo-title"><strong>WERACLE</strong><br>EZEN Smart Academy</v-list-item-title>
            <v-btn v-if="!mini" icon @click.stop="menuToggle" class="btn-close" >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>

          <v-list class="menu-list">
            <!-- S:학생 메뉴 -->
            <template>
              <v-list-item link to="/" title="홈">
                <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>홈</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/coding-editor" title="코드씽크" v-if="!loginUser.isTeacher&&loginUser.isIT">
                <v-list-item-icon><v-icon>mdi-file-code-outline</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>코드씽크</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/coding-test" title="코드위드미" v-if="!loginUser.isTeacher&&loginUser.isIT">
                <v-list-item-icon><v-icon> mdi-comment-text-multiple</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>코드위드미</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/code-review" title="코드 리뷰" v-if="!loginUser.isTeacher&&loginUser.isIT">
                <v-list-item-icon><v-icon>mdi-xml</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>코드 리뷰</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/quiz" title="코딩 퀴즈" v-if="!loginUser.isTeacher&&loginUser.isIT">
                <v-list-item-icon><v-icon>mdi-quora</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>퀴즈코딩</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- E:학생 메뉴 -->


            <!-- S:관리자 메뉴 -->
            <template v-if="loginUser.isManager">
              <v-divider></v-divider>
              <v-list-item link to="/admin/lectures" target="_blank" title="관리자 모드">
                <v-list-item-icon><v-icon>mdi-account-supervisor-circle</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>관리자모드</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- E:관리자 메뉴 -->

            <!-- v-list-item link title="훈련생 유의사항" href="https://ezen-academy.gitbook.io/lms_precautions/" target="_blank" class="other-btn mt-auto" v-if="!loginUser.isManager">
              <v-list-item-icon><v-icon>mdi-alert-circle-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>훈련생 유의사항</v-list-item-title>
              </v-list-item-content>
            </v-list-item -->

            <v-list-item link title="기초 IT 용어집" href="https://ezen-academy.gitbook.io/ezen-it-book-web-development/" target="_blank" class="other-btn mt-auto" v-if="!loginUser.isManager">
              <v-list-item-icon><v-icon>mdi-book-open-page-variant-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>기초 IT 용어집</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link title="Smart LMS 사용자 가이드" href="https://ezen-academy.gitbook.io/smart-lms/" target="_blank" class="other-btn" v-if="!loginUser.isManager">
              <v-list-item-icon><v-icon>mdi-book-search-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="letter-spacing: -0.05em;">스마트 LMS 사용자 가이드</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- v-list-item link title="기업프로젝트 협력신청" href="https://docs.google.com/forms/d/1jc0bq7uOI-mpTcflje2MdHbgEipDPzzQaZIrFaL-gX4/viewform?edit_requested=true" target="_blank" class="other-btn" v-if="!loginUser.isManager">
              <v-list-item-icon><v-icon>mdi-briefcase-arrow-left-right-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>기업프로젝트 협력신청</v-list-item-title>
              </v-list-item-content>
            </v-list-item -->
          </v-list>

        </v-navigation-drawer>

        <my-password-change v-if="ui.passwordChange" @close="ui.passwordChange=false" />
        <my-info-modify v-if="ui.infoChange" @close="ui.infoChange=false" />
        <my-info-social v-if="ui.infoSocial" @close="ui.infoSocial=false"/>

    </div>
</template>

<style lang="scss" scoped>
  .--main-title {white-space:nowrap;}
  .nav {background-color: #fff; color:#232427;}
  .logo-title { color:#232427; font-size:14px; margin-left:15px; line-height:1.2; font-weight: 800;}
  .logo-title > strong { font-size:20.5px; font-weight: 800; letter-spacing:7px; }
  .btn-close {color:#232427 !important; border:1px solid #232427 !important; transform: scale(0.75); margin-right:5px; }
  .menu-list { height: calc(100% - 120px); box-sizing: border-box; display: flex; flex-direction: column;}
  .menu-list .v-list-item { font-size:12px !important; font-weight:700; flex:initial;}
  .menu-list .v-icon{ font-size:22px; display:block; margin:0 auto; }
  .nav .v-list-item {
    position:relative;
    min-height:48px;
    opacity: 0.58;
    &.v-list-item--active {
      opacity:1;
      &:before{
        background-color:#f5f5f5;
        opacity: 0.5;
      }
    }
    &.other-btn {
      background-color:#666;
      border-radius: 10px;
      margin:0 10px;
      color:#fff;
      transition: all 0.2s ease;
      min-height:40px;
      .v-list-item__title{
        font-size:14px;
        position:relative;
        z-index:3;
        letter-spacing: -0.05em;
      }
      .v-list-item__icon {
        margin-right:15px;
      }
      .v-icon {
        color:#fff;
      }
      & + .other-btn {
        margin-top:5px;
      }
      &:focus {
        &:before{
          margin:0;
        }
      }
    }
  }
  .v-list-item--link:before { border-radius: 10px; margin:0 10px; background-color: #fff;}
  .v-list-item--link.v-list-item--active:before {opacity: 1}
  .v-list-item__icon {margin-right:5px; position:relative; z-index:2;}
  .v-list-item__title { position:relative; z-index: 2;}
  .v-application--is-ltr .v-list-item__icon:first-child{margin-right:20px;}
  .logo-box {flex-shrink: 1; border-bottom: 1px solid #e0e0e0; padding:29px 0;}
  .img-box-container {
    width:80px;
    height:60px;
    text-align: center;
  }
  .user-info-container,
  .user-photo-wrap  {width: 100%; padding-right:20px;}
  .user-box {
    width:100%;
    padding-left:277px;
    -webkit-transition:width 0.5s, padding-left 0.5s;
    transition:width 0.5s, padding-left 0.5s;
    border-bottom: 1px solid #e0e0e0;
    height:100px;
    position:relative;
    .close-box {
      position:absolute;
      left:0;
      top:0;
      background-color:#fff;
      width:1000px;
      height:90px;
    }
    &.minify {
      padding-left:75px;
    }
    .img-box {
      position:relative;
      width:80px;
      height:60px;
      > img {
        position:relative;
        z-index: 1;
      }
      > button {
        position:absolute;
        right:0;
        bottom:-5px;
        background: #666;
        color: #fff;
        transform: scale(0.75);
      }
      .img-box {
        > span {
          margin:0;
        }
      }
    }

    .info-box {
      width:100%;
      strong {
        font-size:18px;
      }
    }

  }
  .v-menu__content {
    .v-list {
      padding:0;
      .v-list-item {
        min-height:2rem;
        font-size:14px;
      }
    }
  }
  .minify {
    .nav .v-list-item.other-btn {
      padding:0 6px;
    }
  }
</style>

<script>
import usersModel from "@/models/users.model";
import MyPasswordChange from "@/views/users/MyPasswordChange.vue";
import MyInfoModify from "@/views/users/MyInfoModify.vue";
import MyInfoSocial from "@/views/users/MyInfoSocial.vue";
import jwt from "@/plugins/jwt";

export default {
  components: { MyInfoModify, MyPasswordChange,  MyInfoSocial},
  props: {
    mini: {
      type: Boolean,
      default : false,
      required: false
    }
  },
  mounted () {
    this.miniVariant = this.mini
  },
  data () {
    return {
      dialog: false,
      drawer: true,
      scrollInvoked:0,
      userPhoto: null,
      isChanging: false,
      ui: {
        passwordChange: false,
        infoChange: false,
        infoWithdraw: false,
        infoSocial:false,
        chatGPT: false
      },
      miniVariant: false,
      myLectureList: {
        result: [],
        totalCount: 0,
        page: 1,
        pageRows: 5
      }
    }
  },
  watch: {
    miniVariant () {
      if(this.isChanging) return;
      this.isChanging = true;
      this.$emit('menuToggle', this.miniVariant)
      this.isChanging  =false
    },
    mini () {
      this.miniVariant = this.mini
    }
  },
  methods: {
    menuToggle () {
      this.miniVariant= !this.miniVariant
    },
    signOut() {
      usersModel.signOutProcess()
    },
    /**
     * 사용자 사진 변경을 클릭하고, 이미지를 선택했을때 처리
     */
    async onFileChange () {
      const files = this.$refs.uploadInput.files;

      // 선택된 파일이 없을 경우 나가기
      if(files.length ===0) return;

      // 넘겨줄 데이타를 정리하기
      const formData = new FormData();
      formData.append('userfile', this.$refs.uploadInput.files[0])

      // 일단 이미지 업로드 처리
      let photoUrl = '';
      await jwt.axios.post('/uploads/users', formData, {
        headers: {
          "Content-Type": "multipart/form-vod-data"
        }
      }).then((res) => {
        photoUrl = res.data[0]

        // 파일 선택 초기화
        this.$refs.uploadInput.value = "";
      })

      // 만약 이미지가 업로드 되지 않았다면 이후 과정은 실행하지 않음
      if(photoUrl.length === 0) {
        return;
      }

      // 업로드된 이미지의 경로로 현재 사용자의 이미지 교체하기
      await jwt.axios.patch('/v1/users/photo', {
        photo: photoUrl
      }).then(() => {
        usersModel.getMyInfo()
      })

    },
    onScroll () {
      this.scrollInvoked++
    },
  },


}
</script>
