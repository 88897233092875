import {mapGetters} from "vuex";
import config from "@/config";

export default {
    computed: {
        ...mapGetters({
            isLogin: 'authorize/isLogin',
            loginUser: 'authorize/userInfo',
            isPageLoading : 'layout/isPageLoading',
            useLayout : 'layout/useLayout',
            mainTitle : 'layout/mainTitle'
        })
    },
    filters: {
        numberFormat (value) {
            if(value === 0 || value === '0') return "0"

            const reg = /(^[+-]?\d+)(\d{3})/;
            let n = (value + '');

            while(reg.test(n)) {
                n = n.replace(reg, '$1' + ',' + '$2');
            }

            return n
        },
        nl2br(value) {
            if (!value) return '';
            return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
        },
        dateFormat (value, type) {
            if(! value) return ''
            return value.dateFormat(type)
        },
        snsDateTime (value) {
            if(value === null || value === '') return

            const today = new Date()
            let date = new Date(value);

            const elapsedTime = Math.trunc((today.getTime() - date.getTime()) / 1000);

            let elapsedText = "";
            if (elapsedTime < 10) {
                elapsedText = "방금 전";
            } else if (elapsedTime < 60) {
                elapsedText = elapsedTime + "초 전";
            } else if (elapsedTime < 60 * 60) {
                elapsedText = Math.trunc(elapsedTime / 60) + "분 전";
            } else if (elapsedTime < 60 * 60 * 24) {
                elapsedText = Math.trunc(elapsedTime / 60 / 60) + "시간 전";
            } else if (elapsedTime < (60* 60* 24 * 10)) {
                elapsedText = Math.trunc(elapsedTime / 60 / 60 / 24) + "일 전";
            } else {
                elapsedText = value.dateFormat('yy/MM/dd')
            }

            return elapsedText
        },
        leadingZero(value) {
            if (value >= 1 && value <= 9) {
                return '0' + value;
            }
            return value;
        },
        random(value, min, max) {
            min = parseInt(min);
            max = parseInt(max);

            if (isNaN(min) || isNaN(max)) {
                return value;
            }

            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    },
    methods: {
        openChatGPT() {
            this.openWindow({
                width : 800,
                height : 600,
                url : '/ai-chat'
            });
        },
        preventNonNumericInput (e) {
            if (e.key !== 'Backspace' && e.key !== 'Delete' &&  e.key !== '-' && (e.key < '0' || e.key > '9')) {
                e.preventDefault();
            }
        },
        validatePhoneNumber(value) {
            const phoneRegex = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})([0-9]{3,4})([0-9]{4})$/;
            const phoneWithHypenRegex = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/;
            var transNum = value.replace(/\s/gi, '').replace(/-/gi,'');

            if(transNum.length === 11 || transNum.length === 10) {
                if( phoneRegex.test(transNum) ) {
                    transNum = transNum.replace(phoneWithHypenRegex, '$1-$2-$3');
                    return transNum
                }
            }

            return ''
        },
        setMainTitle(title) {
            this.$store.commit('layout/setMainTitle', title);
        },

        openWindow(options) {
            const defaults={
                title : '_blank',
                width : 800,
                height : 600,
                url : ''
            };

            if(typeof options !== 'undefined') {
                for(let key in options) {
                    defaults[key] = options[key];
                }
            }

            const cw = screen.availWidth;
            const ch = screen.availHeight;
            const sw = options.width;
            const sh = options.height;
            const ml = (cw - sw) / 2;
            const mt = (ch - sh) / 2;
            const option = 'width='+sw+',height='+sh+',top='+mt+',left='+ml+',scrollbars=yes,resizable=no';
            const win = window.open(defaults.url, defaults.title,  option);

            if (win === null)
            {
                this.$swal("팝업 열기 실패","팝업 차단 기능이 설정되어있습니다<br>차단 기능을 해제(팝업허용) 한 후 다시 이용해 주십시오.","error");
            }

        }
    }
}