export default [
    {
        path: '/lectures/:lectureId',
        name:'lectureDetailView',
        component: () => import(/* webpackChunkName: "lectures.view" */ '@/views/lectures/LectureInfo.vue'),
        children: [
            {path: '', name:'lectureDetailHome', component: () => import(/* webpackChunkName: "lectures.view.home" */ '@/views/lectures/LectureInfoHome.vue')},
            {path: 'info', name:'lectureDetailBasic', component: () => import(/* webpackChunkName: "lectures.view.basic" */ '@/views/lectures/LectureInfoBasic.vue')},
            {path: 'attendance', name:'lectureDetailAttendance', component: () => import(/* webpackChunkName: "lectures.view.attendance" */ '@/views/lectures/LectureInfoAttendance.vue')},
            {path: 'qna', name:'lectureDetailQna', component: () => import(/* webpackChunkName: "lectures.view.qna" */ '@/views/lectures/LectureInfoQna.vue')},
            {path: 'notice', name:'lectureDetailNotice', component: () => import(/* webpackChunkName: "lectures.view.Notice" */ '@/views/lectures/LectureInfoNotice.vue')},
            {path: 'data-board', name:'lectureDataBoard', component: () => import(/* webpackChunkName: "lectures.view.dataBoard" */ '@/views/lectures/LectureInfoDataBoard.vue')},
            {path: 'submit-feedback', name:'lectureSubmitFeedback', component: () => import(/* webpackChunkName: "lecture.submitFeedback" */ '@/views/lectures/LectureSubmit.vue')},
            {path: 'students', name:'lectureDetailStudents', component: () => import(/* webpackChunkName: "lectures.view.students" */ '@/views/lectures/LectureInfoStudents.vue')},
            {path: 'code-review', name:'lectureCodeReview', component: () => import(/* webpackChunkName: "lectures.view.codereview" */ '@/views/CodeReview/CodeReviewList.vue')},
            {path: 'code-review/new', name:'lectureCodeReviewAdd', component: () => import(/* webpackChunkName: "lectures.codereview.add" */ '@/views/CodeReview/CodeReviewForm.vue')},
            {path: 'code-review/:id', name:'lectureCodeReviewView', component: () => import(/* webpackChunkName: "lectures.codereview.view" */ '@/views/CodeReview/CodeReviewView.vue')},
            {path: 'code-review/:id/edit', name:'lectureCodeReviewEdit', component: () => import(/* webpackChunkName: "lectures.codereview.edit" */ '@/views/CodeReview/CodeReviewForm.vue')},
            {path: 'coding-test', name:'lectureCodingTest', component: () => import(/* webpackChunkName: "lectures.codingTest.list" */ '@/views/CodeReview/CodingTest.vue')},
            {path: 'coding-test/:id', name:'lectureCodingTestView', component: () => import(/* webpackChunkName: "lectures.codingTest.view" */ '@/views/CodeReview/CodingTestView.vue')},
        ]
    },
    {path:'/lectures/:lectureId/chat', name:'lectureChat', component: () => import(/* webpackChunkName: "lectures.chat" */ '@/views/lectures/LectureChat.vue'), meta: {useLayout: false}}
]