<template>
  <v-app app :style="{minHeight: ui.height + 'px'}">
    <v-navigation-drawer app>
      <v-list-item to="/admin/lectures">
        <v-avatar width="40" rounded="0">
          <img :src="require('@/assets/images/common/logo-green-ezen.png')" class="logo-img" />
        </v-avatar>
        <v-list-item-content class="ml-2">
          <v-list-item-title class="text-h6">WERACLE</v-list-item-title>
          <v-list-item-subtitle>관리자페이지</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <v-list-group prepend-icon="mdi-developer-board" :value="true">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>강의 관리</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link to="/admin/lectures" title="강의 관리">
            <v-list-item-avatar />
            <v-list-item-content>
              <v-list-item-title>강의 관리</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/schedule" title="일정 관리">
            <v-list-item-avatar />
            <v-list-item-content>
              <v-list-item-title>일정 관리</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>

        <v-list-group prepend-icon="mdi-account-supervisor-circle" :value="true" v-if="loginUser.isAdmin">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>사용자 관리</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link to="/admin/users" title="사용자 관리">
            <v-list-item-avatar />
            <v-list-item-content>
              <v-list-item-title>사용자 관리</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>


        <v-list-group prepend-icon="mdi-cogs" :value="true">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>시스템 설정</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link to="/admin/stores" title="지점 관리">
            <v-list-item-avatar />
            <v-list-item-content>
              <v-list-item-title>지점 관리</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/admin/ai-model" title="AI 학습 설정">
            <v-list-item-avatar />
            <v-list-item-content>
              <v-list-item-title>AI 학습 설정</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-item link @click="signOut">
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>로그아웃</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list>
    </v-navigation-drawer>

    <v-main>


      <router-view />
    </v-main>

  </v-app>
</template>

<script>
import usersModel from "@/models/users.model";

export default {
  name: 'AdminApp',
  data () {
    return {
      ui: {
        height:0
      }
    }
  },
  mounted () {

    this.onWindowResize()
    document.addEventListener('resize', this.onWindowResize)
  },
  methods: {
    onWindowResize() {
      this.ui.height = window.innerHeight
    },
    signOut() {
      usersModel.signOutProcess()
    },
  }
}
</script>