<template>

  <w-popup title="소셜로그인 연동" width="500" @close="$emit('close')">
    <v-list>
      <template>
        <v-list-item>
          <v-list-item-icon style="margin-right:16px">
            <svg class="social-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.33 15.2" width="24" height="24">
              <polygon class="cls-1" points="10.39 8.14 4.71 0 0 0 0 15.2 4.94 15.2 4.94 7.06 10.62 15.2 15.33 15.2 15.33 0 10.39 0 10.39 8.14" fill="#03C759"/>
            </svg>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>네이버 로그인</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="myInfo.social_naver&&myInfo.social_naver.length>0" elevation="0" disabled>연동완료</v-btn>
            <v-btn v-else color="primary" elevation="0" @click="onNaverLogin">연동하기</v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
      <v-list-item>
        <v-list-item-icon style="margin-right:16px">
          <svg class="social-icon" width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,36,35">
            <path fill-rule="evenodd"  fill="#FEE500" d="M17.1000,0.970 C8.058,0.970 0.000,7.226 0.000,14.943 C0.000,19.741 3.117,23.973 7.863,26.489 L5.866,33.819 C5.689,34.468 6.427,34.984 6.993,34.608 L15.747,28.803 C16.485,28.874 17.236,28.916 17.1000,28.916 C27.941,28.916 35.1000,22.659 35.1000,14.943 C35.1000,7.226 27.941,0.970 17.1000,0.970 "/>
          </svg>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>카카오 로그인</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn v-if="myInfo.social_kakao&&myInfo.social_kakao.length>0" elevation="0" disabled>연동완료</v-btn>
          <v-btn v-else color="primary" elevation="0" @click="onKakaoLogin">연동하기</v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider />
        <v-list-item>
          <v-list-item-icon style="margin-right:16px">
            <svg width="28" height="27" viewBox="0 0 33 32" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M29.6333 16.3051C29.6333 15.3592 29.549 14.4511 29.3942 13.5781H17.0078V18.7356H24.0863C23.7815 20.4024 22.8547 21.8142 21.462 22.7601V26.105H25.7131C28.1985 23.7838 29.6333 20.366 29.6333 16.3051Z" fill="#3E82F1"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M17.0071 29.3349C20.5581 29.3349 23.5355 28.1404 25.7112 26.1043L21.4601 22.7594C20.2818 23.5595 18.7764 24.0318 17.0059 24.0318C13.58 24.0318 10.6808 21.6867 9.64619 18.5352H5.25391V21.9894C7.41845 26.3467 11.8652 29.3349 17.0071 29.3349Z" fill="#32A753"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M9.64783 18.5369C9.38516 17.7368 9.23524 16.8826 9.23524 16.0034C9.23524 15.1241 9.38516 14.27 9.64783 13.4698V10.0156H5.25431C4.36346 11.8156 3.85547 13.8517 3.85547 16.0034C3.85547 18.155 4.36346 20.1911 5.25431 21.9911L9.64783 18.5369Z" fill="#F9BB00"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M17.0071 7.97114C18.9375 7.97114 20.6721 8.6444 22.035 9.96454L25.8078 6.13978C23.5293 3.98937 20.5519 2.66797 17.0071 2.66797C11.8652 2.66797 7.41845 5.65619 5.25391 10.0135L9.64743 13.4678C10.682 10.3162 13.5813 7.97114 17.0071 7.97114Z" fill="#E74133"></path>
            </svg>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>구글 로그인</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="myInfo.social_google&&myInfo.social_google.length>0" elevation="0" disabled>연동완료</v-btn>
            <v-btn v-else color="primary" elevation="0" @click="onAppleSignIn">연동하기</v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </w-popup>
</template>
<script>
import jwt from "@/plugins/jwt";
import socialModel from "@/models/social.model";
import config from "@/config";

export default {
  data () {
    return {
      device: 'browser',
      myInfo: {
        social_naver: '',
        social_kakao: '',
        social_google: ''
      }
    }
  },
  computed: {
    apiHost () {
      return (process.env.NODE_ENV === 'production' ? config.prodApiHost : config.devApiHost)
    },
    cookieHost () {
      return (process.env.NODE_ENV === 'production' ? '.ezenlms.co.kr' : 'localhost:8383')
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    onNaverLogin () {
      this.$cookies.set('loginUid', this.loginUser.id,'','/',this.cookieHost)
      this.openWindow({
        url:  `${this.apiHost}/social/naver`,
        width: 800,
        height: 600
      })
    },
    onAppleSignIn() {
      this.$cookies.set('loginUid', this.loginUser.id,'','/',this.cookieHost)
      this.openWindow({
        url:  `${this.apiHost}/social/google`,
        width: 800,
        height: 600
      })
    },
    onKakaoLogin () {
      this.$cookies.set('loginUid', this.loginUser.id,'','/',this.cookieHost)
      this.openWindow({
        url:  `${this.apiHost}/social/kakao`,
        width: 800,
        height: 600
      })
    },
    getInfo() {
      jwt.axios.get('/v1/users')
        .then(res => {
          for(let key in res.data) {
            if(typeof this.myInfo[key] !== 'undefined') {
              this.myInfo[key] = res.data[key]
            }
          }
        })
    }
  },
  metaInfo() {
    return {
      title: '소셜 로그인 정보',
      meta: [
        {vmid:'og:title', property:'og:title', content: `소셜 로그인 정보`}
      ]
    }
  }
}
</script>